<template>
  <Layout>
    <PageHeader :title="getTitle" :items="items" />
    <div class="card p-3">
      <div class="d-flex">
        <div class="me-1" v-for="(m, key) in menu" :key="key">
          <b-button
            @click="activeMenu = key"
            :variant="activeMenu == key ? 'primary' : 'outline-primary'"
            class="text-capitalize d-flex align-items-center"
          >
            <i :class="[parentIcon[key], 'me-1 fs-16']"></i>
            {{ changeName(m.category) }}</b-button
          >
        </div>
      </div>
      <nav class="mt-3">
        <div class="d-flex g-2" style="flex-wrap: wrap">
          <router-link
            v-for="(submenu, index) in menu[activeMenu].submenu"
            :key="index"
            :to="{ name: submenu.name }"
            custom
            v-slot="{ href, navigate }"
          >
            <a
              :href="href"
              class="sidebar-integration-item p-3 me-4 mb-4 rounded"
              :style="{
                maxWidth: '350px',
                backgroundColor:
                  activeSubMenuSlug === submenu.slug ? '#FFF2D0' : '#FFFBF2',
              }"
              @click="
                (e) => {
                  navigate(e);
                  showPage();
                  activeSubMenuSlug = submenu.slug;
                }
              "
            >
              <div
                class="rounded"
                :style="{
                  width: '100% !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }"
              >
                <span class="flex-shrink-0">
                  <span
                    class="badge badge-soft-secondary p-2 position-relative"
                  >
                    <ImgLazy
                      :src="`/img/new-channels/${submenu.icon}`"
                      error-img="/img/new-channels/integration.png"
                      :alt="submenu.name"
                      style="height: 100% !important; width: 30px !important"
                    />
                  </span>
                </span>

                <div
                  class="badge-integration"
                  :style="{
                    backgroundColor:
                      submenu.total_integration_active > 0
                        ? '#0ab39c'
                        : '#c9c9c9',
                  }"
                >
                  {{ submenu.total_integration_active }} Integration(s)
                </div>
              </div>
              <div class="mt-3">
                <strong
                  class="flex-grow-1 overflow-hidden fs-14 text-truncate font-bold text-dark"
                >
                  {{ submenu.subMenuName }}
                </strong>
                <p class="text-muted mb-0">
                  {{ submenu.description }}
                </p>
              </div>
            </a>
          </router-link>
        </div>
      </nav>
    </div>
    <div class="container-integration">
      <div
        class="flex-1"
        :class="resMobile ? (!sidePage ? '' : 'd-none') : 'lg-show'"
      >
        <div class="card" style="min-height: calc(100vh - 200px)">
          <span class="d-flex flex-row align-items-center">
            <i
              v-if="this.resMobile"
              @click="removePage()"
              class="ri-arrow-left-s-line align-bottom mx-3"
              style="font-size: 2em"
            ></i>
            <p class="ms-2 mt-3" v-if="this.resMobile" @click="removePage()">
              Back
            </p>
          </span>

          <router-view></router-view>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
// import SecondSideBar from "@/views/common/components/SecondSidebar";
// import SettingSecondSideBar from "@/views/common/components/SettingSecondSidebar";
import _ from "lodash";
import { mapGetters } from "vuex";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import ImgLazy from "@/components/base/ImgLazy.vue";

export default {
  components: {
    Layout,
    PageHeader,
    ImgLazy,
    // SecondSideBar
    // SettingSecondSideBar
  },
  computed: {
    ...mapGetters({
      integrations: "integration/integrations",
    }),
    changeWidthUserWindow() {
      this.onResize();
      return "";
    },
    menu() {
      let menu = _.chain(this.subSubMenu)
        .groupBy("category")
        .map((value, key) => ({ category: key, submenu: value }))
        .value()
        .map((menu) => ({
          ...menu,
          submenu: menu.submenu.map((submenu) => ({
            ...submenu,
            description: `Tingkatkan Komunikasi dan Layanan Pelanggan dengan Integrasi ${submenu.subMenuName}`,
            total_integration_active:
              this.integrations
                .find((r) => r.name === submenu.slug)
                ?.integration.filter((r) => r.status)?.length || 0,
          })),
        }));
      return menu;
    },
    getTitle() {
      return "Integration";
    },
  },
  data() {
    return {
      sidePage: true,
      windowWidth: window.innerWidth,
      resMobile: false,
      items: [
        {
          text: "Channel",
          href: "/",
        },
        {
          text: "Channel Integration",
          active: true,
        },
      ],
      parentIcon: [
        "ri-chat-1-line",
        "ri-global-line",
        "ri-apps-line",
      ],
      subSubMenu: [
        {
          slug: "whatsapp",
          name: "integration.whatsapp",
          icon: "whatsapp.svg",
          category: "messenger",
          subMenuName: "Whatsapp",
        },
        {
          slug: "facebook",
          name: "integration.facebook",
          icon: "facebook.svg",
          category: "social-media",
          subMenuName: "Facebook",
        },
        {
          slug: "instagram",
          name: "integration.instagram",
          icon: "instagram.svg",
          category: "social-media",
          subMenuName: "Instagram",
        },
        {
          slug: "telegram",
          name: "integration.telegram",
          icon: "telegram.svg",
          category: "social-media",
          subMenuName: "Telegram",
        },
        {
          slug: "email",
          name: "integration.email",
          icon: "email.svg",
          category: "apps",
          subMenuName: "Email",
        },
        {
          slug: "webchat",
          name: "integration.webchat",
          icon: "webchat.svg",
          category: "apps",
          subMenuName: "Embeddable Web Chat",
        },
      ],
      activeMenu: 0,
      activeSubMenuSlug: "whatsapp",
    };
  },
  created() {
    this.onResize();
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showPage() {
      // this.onResize()
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.sidePage = false;
      } else {
        console.log("desktop view");
        this.sidePage = true;
      }
    },
    removePage() {
      this.sidePage = true;
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      // if data already clicked
      console.log("data selected");
      // mobile checker
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.resMobile = true;
      } else {
        console.log("desktop view");
        this.resMobile = false;
        this.sidePage = true;
      }
    },
    isActive(slug) {
      console.log("is active", window.location.pathname.split("/")[4]);
      return window.location.pathname.split("/")[4] == slug;
    },
    changeName(cat) {
      let name = cat;
      switch (cat) {
        case "messenger":
          name = "Messengers";
          break;
        case "social-media":
          name = "Social Media";
          break;
        case "apps":
          name = "Apps";
          break;
        default:
          name = cat;
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge img {
  width: 24px;
}
.channel-list .channel {
  padding: 5px;
  border-radius: 4px;
}
.channel-list .channel:hover {
  background: var(--vz-boxed-body-bg);
}
.channel-list {
  overflow: scroll;
  max-height: calc(100vh - 200px);
}

.container-integration {
  display: flex;
  gap: 10px;

  & .sidebar-integration {
    background-color: #fff;
    width: 260px;
    padding: 0px 10px;
    max-height: calc(100vh - 200px);

    @media screen and (max-width: 769px) {
      width: 100%;
    }

    &-item {
      display: flex;
      margin-bottom: 5px;
      background-color: #f8fafc;
      padding: 4px;
      border-radius: 5px;
      align-items: center;

      &:hover {
        background-color: #f1f5f9;
      }
    }
  }
}
.badge-integration {
  display: inline-flex;
  min-width: 20px;
  // background-color: #2bbc93;
  margin-right: 5px;
  border-radius: 4px;
  padding: 2px 4px;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.flex-1 {
  flex: 1;
}
</style>
